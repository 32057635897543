/* Variables from Automotive Portal */

@import './home';
@import './icons';
@import './validation';
@import './fonts.scss';
@import './colors.scss';



//Webshop variables
$brand-background-color: $primary;
$footer-background-color: #E0E0E0;

$sidebar-text-colour: $text-color;
$sidebar-active-item-text-colour: $primary;
$sidebar-active-item-vertical-line-colour: $primary;
$sidebar-background: $background-primary;
$sidebar-inactive-item-background: $background-disabled;
$sidebar-inactive-item-text-color: $text-disabled-color;

$cookie-background-color: $secondary;

$tab-non-active-text-color: $text-color;
$tab-active-text-color: $text-color;
$tab-alignment: center;
$tab-text-transform: uppercase;


/* Form-control */
$field-color: $text-color;
$field-background-color: $white;
$field-border-radius: 0.25rem;
$field-border-color: $border-color;
$field-border-width: 1px;
$field-font-size: 1rem;
$field-disabled-text-color: $text-disabled-color;

$bottom-delimiter-color: $border-color;
$plan-delimiter: $border-color;

$required-fields-text-color: $text-disabled-color;

/* Header Logo */
$logo-margin-top: 0px;

$step-container-bg: $primary;

$step-active-badge-bg: $white;
$step-active-text-color: $secondary;

$step-inactive-badge-bg: $secondary;
$step-inactive-text-color: $white;
$step-border-color: $white;

$step-item-name-text-color: $white;

$vehicle-scan-bg: linear-gradient(180deg, #333333 2.15%, #333333 100%);


$icon-circle-dark: #3F3F46;
$icon-circle-light: #F1F1F1;
$vehicle-icon-bg: $primary;

$scan-line-bg: $tertiary;
$pinpoint-bg: $secondary;
$pinpoint-circle-bg: $tertiary;

$vehicle-status-compatible-bg: #779F2D;
$vehicle-status-incompatible-bg: #DC2626;
$vehicle-status-text-color: $white;

$footer-text-color: $secondary;
$footer-text-color-hover: $black;

//Bootstrap variables
$btn-border-radius: 0.25rem;
$btn-font-weight: normal;
$nav-tabs-link-active-bg: transparent;

/* Switch Button variables */
$switch-btn-checked-color: $primary;

//Login in Partnership with Logo
$partnershipBrandLogo: '/';
@import 'variables.scss';
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "light": $white,
    "dark": $black
);

.tips-container {
    span {
        color: $secondary !important;
    }
}

.footer-container {
    .footer-logo {
        background-size: contain !important;
    }
}

.partnership-text-center {
    display: none !important;
}

.partnershipLogo {
    background-position: 50% 35%;
    margin: 1px;
    padding: 0px;

}